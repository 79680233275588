
















import Vue, { PropOptions } from 'vue';

export type FooterSocialIcon = Blok & {
  name: string;
  icon: BlokFieldAsset;
  link: BlokFieldLink;
};

export default Vue.extend({
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<FooterSocialIcon>,
  },
});
