import { render, staticRenderFns } from "./FooterV2.vue?vue&type=template&id=b879f6e8&scoped=true&"
import script from "./FooterV2.vue?vue&type=script&lang=ts&"
export * from "./FooterV2.vue?vue&type=script&lang=ts&"
import style0 from "./FooterV2.vue?vue&type=style&index=0&id=b879f6e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b879f6e8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterSocialIconV2: require('/home/circleci/app/components/storyblok/FooterSocialIconV2.vue').default})
