






















































































import Vue, { PropOptions } from 'vue';
import type { FooterSocialIcon } from '~/components/storyblok/FooterSocialIconV2.vue';
import type { Dropdown } from '~/components/storyblok/Dropdown.vue';

export type Footer = Blok & {
  logo: BlokFieldAsset;
  shortDescription: string;
  socialIcons: FooterSocialIcon[];
  navLinks: Dropdown[];
  privacyPolicyUrl: BlokFieldLink;
  termsOfUseUrl: BlokFieldLink;
};

export default Vue.extend({
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<Footer>,
  },
});
